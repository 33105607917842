$accent: #738d43;
$loader-color: #738d43;
$white: #000000; // its funny, get it?
$sidebar-color: #221616;
$background-color: #f8f1ca;
$icon-color: rgb(255, 245, 245);
$title-color: #ffd700;
$sub-color: #6d6d6d;
$icon-highlight-color: #4d4d4e;

